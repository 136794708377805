import React from "react";
import PropTypes from "prop-types";
import { CustomHits } from "../CardGrid";
import { CustomMenu, CustomMenuSelect } from "../Search";

import { Index, Configure } from "react-instantsearch-dom";
import useWindowSize from "../../../hooks/useWindowSize";
import { breakpoints } from "../../../../styles/theme";

const HitsInIndex = ({ index, config }) => {
  const { width } = useWindowSize();
  const isMobile = width < breakpoints.md;

  const {
    search_maxColumns,
    search_hitsPerPage,
    search_grid_fixedPushEnable,
    search_grid_fixedPushMobilePosition,
    search_grid_fixedPushRowPosition,
    search_grid_fixedPushColumnPosition,
  } = config;

  return (
    <Index indexName={index.name}>
      <Configure hitsPerPage={config.search_hitsPerPage} />
      {isMobile ? (
        <CustomMenuSelect attribute="type" />
      ) : (
        <CustomMenu attribute="type" />
      )}
      <CustomHits
        maxColumns={search_maxColumns}
        hitsPerPage={search_hitsPerPage}
        fixedPushEnable={search_grid_fixedPushEnable}
        fixedPushMobilePosition={search_grid_fixedPushMobilePosition}
        fixedPushRowPosition={parseInt(search_grid_fixedPushRowPosition)}
        fixedPushColumnPosition={parseInt(search_grid_fixedPushColumnPosition)}
      />
    </Index>
  );
};

HitsInIndex.propTypes = {
  index: PropTypes.object,
  config: PropTypes.object.isRequired,
};

const SearchResult = ({ indices, config }) =>
  indices?.map(index => (
    <HitsInIndex index={index} key={index.name} config={config} />
  ));

SearchResult.propTypes = {
  indices: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
};

export default SearchResult;
