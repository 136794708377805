import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { navigate } from "@reach/router";
import { useTranslation } from "react-i18next";
import { useTheme } from "emotion-theming";

import Modal from "src/components/Molecules/Modal";
import GlobalContainer from "./GlobalContainer";
import Footer from "./Molecules/Footer";
import {
  NewsletterModal,
  adapter as newsletterAdapter,
  NewsletterModalSuccess,
  newsletterModalSuccessAdapter,
} from "./Molecules/Newsletter";

import Share, { adapter as shareAdapter } from "./Molecules/Share";
import BurgerMenu from "./Organisms/BurgerMenu";
import SEO from "./seo/seo";
import Search from "./Molecules/Search";
import { UIContext } from "../contexts/ui";
import AdBanner from "../components/Atoms/AdBanner";

const searchIndices = [
  {
    name: `${process.env.GATSBY_WEBSITE}_INDEX`,
  },
];

const Layout = ({
  children,
  seoData,
  ads,
  location,
  handleFocusInput,
  isSearch,
}) => {
  // handle Modal events
  const videoRef = useRef();
  const [modalsState, setModalsState] = useState(false);
  const openModal = useCallback(({ modalId, modalParams }) => {
    setModalsState({
      ...modalParams,
      [modalId]: true,
    });
  }, []);

  const closeModal = useCallback(
    params => {
      const { modalId, modalParams } = params || {};
      modalId
        ? setModalsState({
            ...modalParams,
            [modalId]: false,
          })
        : setModalsState({
            ...modalParams,
            overlayVideo: false,
            newsletter: false,
            newsletterSuccess: false,
            share: false,
            search: false,
            embedVideo: null,
          });
      navigate(location?.pathname);
    },
    [location]
  );

  useEffect(() => {
    const queryString =
      typeof window !== "undefined" ? window.location.search : "";
    const urlParams = new URLSearchParams(queryString);
    const videoId = urlParams && urlParams.get("openplayer");
    const videoPrice = urlParams && urlParams.get("price");
    const enablePreroll = urlParams && urlParams.get("preroll");

    if (videoId) {
      openModal({
        modalId: "inPlayer",
        modalParams: {
          video: { id: videoId, price: videoPrice, preroll: enablePreroll },
        },
      });
    }
  }, [openModal]);

  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    const queryString =
      typeof window !== "undefined" ? window.location.search : "";
    const urlParams = new URLSearchParams(queryString);
    const autoOpen = urlParams && urlParams.has("openNewsletter");
    if (autoOpen) {
      openModal({
        modalId: "newsletter",
        modalParams: { template: "allNewsletterModal" },
      });
    }
  }, [openModal]);

  return (
    <Root>
      {seoData && <SEO {...seoData} />}
      <UIContext.Provider value={{ modalsState, openModal, closeModal }}>
        {!ads?.smartadserver_disable_masthead && (
          <AdBanner
            config={{
              ...ads,
              smartadserver_format_id: 96773,
              isSticky: true,
              bgColor: "black",
            }}
          />
        )}
        <GlobalContainer>
          <BurgerMenu isSearch={isSearch} handleFocusInput={handleFocusInput} />
          <main>{children}</main>
          <Footer />
        </GlobalContainer>
        <div id="modalsRoot">
          {/* Internal Video Modal */}
          <Modal
            isOpen={!!modalsState.overlayVideo}
            onClose={() => {
              closeModal({
                modalId: "overlayVideo",
                modalParams: modalsState,
              });
            }}
            isFullScreen
            isDark
          >
            <div className="iframe-container">
              <iframe
                ref={videoRef}
                src={`https://player.vimeo.com/video/${modalsState?.video?.id}?autoplay=1&playsinline=0&color=ef0000&byline=0&portrait=0&api=1`}
                width="480"
                height="360"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                allow="autoplay"
              />
            </div>
          </Modal>

          {/* Newsletter Modal */}
          <Modal
            isOpen={!!modalsState.newsletter}
            isScrollable
            onClose={() => {
              closeModal({ modalId: "newsletter", modalParams: modalsState });
            }}
          >
            <NewsletterModal
              {...newsletterAdapter(
                {
                  template: modalsState.template,
                },
                { t }
              )}
              inPopin
            />
          </Modal>
          {/* Newsletter Success Modal */}
          <Modal
            isOpen={!!modalsState.newsletterSuccess}
            onClose={() => {
              closeModal({
                modalId: "newsletterSuccess",
                modalParams: modalsState,
              });
            }}
          >
            <NewsletterModalSuccess
              {...newsletterModalSuccessAdapter(
                {
                  template: modalsState.template,
                },
                { t }
              )}
            />
          </Modal>
          {/* Share Modal */}
          <Modal
            isOpen={!!modalsState.share}
            onClose={() => {
              closeModal({ modalId: "share", modalParams: modalsState });
            }}
          >
            <Share
              {...shareAdapter(
                {
                  template: modalsState.template,
                },
                { t, theme }
              )}
            />
          </Modal>
          {/* Search Modal */}
          <Modal
            isOpen={!!modalsState.search}
            onClose={() => {
              closeModal({ modalId: "search", modalParams: modalsState });
            }}
            isSearch
          >
            <Search indices={searchIndices} location={location} />
          </Modal>
        </div>
      </UIContext.Provider>
    </Root>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoData: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  handleFocusInput: PropTypes.func,
  isSearch: PropTypes.bool,
  ads: PropTypes.object,
};
Layout.defaultProps = {
  handleFocusInput: () => {},
  theme: "default",
};

export default Layout;

// This is needed because otherwise emotion will duplicate style tags in the DOM
const Root = styled.div``;
