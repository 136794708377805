import { adapter as cardGridItemAdapter } from "../CardGridItem";

export default ({ title, cards, catId, catLabel }) => {
  return {
    title,
    items:
      cards &&
      cards.map(card => {
        const { data: cardsData, id, uid, type, url } =
          card?.node || card.article.document || {};
        return {
          ...cardGridItemAdapter({
            id,
            uid,
            type,
            catId,
            catLabel,
            url,
            ...cardsData,
          }),
        };
      }),
  };
};
